import { CodeBlock } from '@atlaskit/code';
import GlobalTheme from '@atlaskit/theme/components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { BsLink } from 'react-icons/bs';
import { MdAccessTimeFilled, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import StopWatch from './StopWatch';
import { formatTime, getTimeDiff } from './utils';

interface IStepContainerProps {
  address?: string;
  prevRuntime?: number;
  prevLines?: number;
  sql?: string;
  onExecuteText: string;
  onExecute: () => Promise<unknown> | unknown;
  beforeExecute?: () => void;
  onComplete?: (payload: unknown, runtime: number) => void;
  rpcs?: number;
}

export default function CodeSection({
  address,
  prevRuntime,
  prevLines,
  sql,
  onExecuteText,
  beforeExecute,
  onExecute,
  onComplete,
  rpcs = 0,
}: IStepContainerProps) {
  const [codeShown, toggleCodeShown] = useState(true);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [finishTime, setFinishTime] = useState<Date | null>(null);
  const [payloadShown, togglePayloadShown] = useState(true);
  const [showSql] = useState(false);
  const [result, setResult] = useState<unknown>(null);
  const [totalRuntime, setTotalRuntime] = useState<string | undefined>(
    formatTime(prevRuntime)
  );
  const [lines, setLines] = useState<number | undefined>(prevLines);
  useEffect(() => {
    setTotalRuntime(formatTime(prevRuntime));
  }, [prevRuntime]);

  useEffect(() => {
    setLines(prevLines);
  }, [prevLines]);

  return (
    <>
      <div className="inline-flex w-full items-center justify-between">
        <div className="inline-flex items-center space-x-2">
          <MdAccessTimeFilled size="1rem" className="text-white" />
          <span className="uppercase text-white">RUNTIME</span>
          <span className="text-yellow-500 font-bold">
            {totalRuntime || '00:00:00.00'}
          </span>
        </div>
        <div className="inline-flex items-center space-x-2">
          <MdEdit size="1rem" className="text-white" />
          <span className="uppercase text-white">Code</span>
          <span className="text-yellow-500 font-bold">
            {lines || '0'} lines
          </span>
        </div>
        <div className="inline-flex items-center space-x-2">
          <BsLink size="1rem" className="text-white" />
          <span className="uppercase text-white">RPC</span>
          <span className="text-yellow-500 font-bold">{rpcs || '0'} RPCs</span>
        </div>
      </div>
      <div className="inline-flex w-full items-center justify-between">
        <div
          className="inline-flex w-auto items-center cursor-pointer space-x-2"
          onClick={() => toggleCodeShown((prev) => !prev)}
        >
          <span className="text-white text-lg">Code</span>
          <AiFillCaretDown
            size="1rem"
            color="#fff"
            className={classNames(
              'transition-transform',
              codeShown ? 'rotate-180' : ''
            )}
          />
        </div>
        {/* {sql && (
          <div className="w-auto inline-flex space-x-2 items-center">
            <input
              type="checkbox"
              checked={showSql}
              className="checkbox checkbox-primary"
              onChange={(e) => toggleShowSql(e.target.checked)}
            />
            <span className="text-white text-lg">SQL</span>
          </div>
        )} */}
      </div>
      <div
        className={classNames(
          'relative flex flex-col w-full rounded-none transition-height ease-in-out overflow-scroll',
          codeShown ? 'h-[25%]' : 'h-0 opacity-0'
        )}
      >
        <GlobalTheme.Provider value={() => ({ mode: 'dark' })}>
          <CodeBlock
            language={showSql ? 'SQL' : 'TSX'}
            showLineNumbers={true}
            text={showSql && sql ? sql : onExecuteText}
          />
        </GlobalTheme.Provider>
        <div className="absolute right-0 bottom-0 inline-flex space-x-2 items-center">
          <StopWatch
            className="text-white w-full text-center text-md"
            startedAt={startTime}
            endedAt={finishTime}
          />
          <button
            className={classNames(
              'btn btn-primary btn-sm text-sm font-bold rounded-none',
              startTime !== null && finishTime === null && 'loading'
            )}
            onClick={async () => {
              if (!address) {
                toast.warning('Please enter a token id');
                return;
              }

              beforeExecute && beforeExecute();
              setFinishTime(null);
              const sTime = new Date();
              setStartTime(sTime);
              const payload = await onExecute();
              const fTime = new Date();
              setFinishTime(fTime);
              setLines((`${onExecuteText}`.match(/\n/g) || '').length + 1);
              setResult(payload);
              onComplete && onComplete(payload, getTimeDiff(sTime, fTime));
            }}
          >
            {startTime !== null && finishTime === null ? 'Loading' : 'Execute'}
          </button>
        </div>
      </div>
      <div
        className={classNames(
          'inline-flex w-full items-center space-x-2 cursor-pointer transition-height'
        )}
        onClick={() => togglePayloadShown((prev) => !prev)}
      >
        <span className="text-white text-lg">Result</span>
        <AiFillCaretDown
          size="1rem"
          color="#fff"
          className={classNames(
            'transition-transform',
            payloadShown ? 'rotate-180' : ''
          )}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col w-full rounded-lg transition-height ease-in-out overflow-scroll',
          payloadShown ? 'h-[25%]' : 'h-0 opacity-0'
        )}
      >
        <GlobalTheme.Provider value={() => ({ mode: 'dark' })}>
          <CodeBlock
            language="JSON"
            showLineNumbers={true}
            text={`${JSON.stringify(result, null, 2)}`}
          />
        </GlobalTheme.Provider>
      </div>
    </>
  );
}
