import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Demo2 from './Demo2';
import ScrollToTop from './ScrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Demo2 />} />
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
