import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    // skip scroll change
    if (location.pathname.match(/\/datasets\/\w\/\w/)) {
      return;
    }
    if (location.pathname.match(/\/connectors\/\w\/\w/)) {
      return;
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}
