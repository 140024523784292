import { padStart, toInteger } from 'lodash';

export const getTimeInSec = (date?: Date | null) => {
  if (!date) {
    return 0;
  }
  return date.getTime();
};
export const padZero = (num: number) => padStart(`${num}`, 2, '0');

export type InputDate = Date | null | undefined;

export const getTimeDiff = (start: InputDate, end: InputDate) => {
  return getTimeInSec(end) - getTimeInSec(start);
};

export const formatTime = (renderTime: any) => {
  const mills = toInteger(renderTime);
  // const sec = padZero(toInteger((renderTime / 1000) % 60));
  // const min = padZero(toInteger((renderTime / 1000 / 60) % 60));
  // const hour = padZero(toInteger((renderTime / 1000 / 60 / 60) % 60));
  return `${mills} ms`;
};
