import Logo from './assets/zettablock-logo.svg';
import Shock from './assets/shock.svg';
import Chill from './assets/chill.svg';
import Diagram from './assets/diagram.png';
import ZettaWhite from './assets/zetta-white.svg';
import { FiSend } from 'react-icons/fi';
import { FaLinkedinIn } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';
import { SiDiscord } from 'react-icons/si';
import { FaSlackHash } from 'react-icons/fa';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useMemo, useRef, useState } from 'react';
import CodeSection from './Code2';
import INFO, { ZTBL_INFO } from './info';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import produce from 'immer';

export default function Demo2() {
  const startRef = useRef<HTMLDivElement>(null);
  const start2Ref = useRef<HTMLDivElement>(null);
  const [address, setAddress] = useState('8000');
  const [ztblRuntime, setZtblRuntime] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [tablePayload, setTablePayload] = useState<any>(null);
  const [useZB, setUseZB] = useState(false);
  const [numberOfLines, setNumberOfLines] = useState<number[]>(
    Array(INFO.length).fill(0)
  );
  const [totalRuntime, setTotalRuntime] = useState<number[]>(
    Array(INFO.length).fill(0)
  );
  const [apiPayloads, setApiPayloads] = useState<any[]>(
    Array(INFO.length - 1).fill({})
  );

  // const executeScroll = () => startRef.current?.scrollIntoView();
  const executeScroll2 = () => start2Ref.current?.scrollIntoView();

  const [transactions, setTransactions] = useState([]);

  const [rpcs, setRpcs] = useState([0, 0, 0, 0]);
  const [totalRpcsForZB, setTotalRpcsForZB] = useState(0);

  const totalRpcs = useMemo(() => {
    return rpcs.reduce((acc, cur) => acc + cur, 0);
  }, [rpcs]);

  return (
    <div className="w-full h-full bg-gradient-to-b from-[#1b1b1b] to-[#1c2643] flex flex-col">
      <div className="inline-flex justify-between items-center w-screen h-full px-[8vw] py-[2vh]">
        <div className="inline-flex w-auto h-full items-center space-x-2">
          <img src={Logo} alt="logo" className="h-[3vh] w-auto" />
          <span className="h-full flex items-end text-white font-extrabold text-base">
            DEMO
          </span>
        </div>
        <a href="https://www.zettablock.com/" target="_blank" rel="noreferrer">
          <button className="btn btn-primary btn-md normal-case text-xl">
            Find Out More
          </button>
        </a>
      </div>
      <div className="inline-flex w-full items-center justify-between px-[8vw] py-[2vh]">
        <div className="flex-col flex w-[30%] space-y-20">
          <div className="flex flex-col w-full space-y-5">
            <span className="text-4xl text-white font-bold">
              Get All Transactions for a token in BAYC (Bored Ape Yacht Club)
            </span>
            <span className="text-accent">
              OpenLand is a NFT marketplace that wants to get all of the
              transactions for a token in BAYC collections in a readable format.
              Provide transparencies to end users could boost trading volumes by
              30% on the platform.
            </span>
          </div>
          <div className="flex flex-col w-full space-y-5">
            <span className="text-2xl text-white text-opacity-80 font-bold">
              HOW TO DO
            </span>
            <ol className="text-accent">
              <li>1. Get all transactions history from a data provider.</li>
              <li>2. Get the eth prices from price data provider.</li>
              <li>3. Get ENS labels from address in the transactions.</li>
            </ol>
          </div>
          <a
            href="https://youtu.be/1kPN2iW4uc8"
            target="_blank"
            rel="noreferrer"
          >
            <button
              // onClick={executeScroll}
              className="btn btn-primary normal-case w-1/2 inline-flex items-center justify-between text-xl"
            >
              Demo Video
              <AiOutlineArrowRight size="1rem" />
            </button>
          </a>
        </div>
        <div className="flex-col flex w-[50%]">
          <img src={Diagram} alt="diagram" className="h-auto w-full" />
        </div>
      </div>
      <div className="h-[6vh]" />
      <div className="flex flex-col w-full px-[8vw] py-[2vh] space-y-14">
        <div
          className="w-full inline-flex items-center justify-between"
          ref={startRef}
        >
          <div className="flex flex-col w-[30%]">
            <span className="uppercase text-lg text-primary">Step 1</span>
            <span className="uppercase text-2xl text-white font-bold">
              Input a Token ID
            </span>
          </div>
          <div className="flex flex-col w-1/2 space-y-2">
            <span className="uppercase text-lg text-white text-opacity-30">
              Token ID
            </span>
            <div className="relative inline-flex h-[5vh] w-full items-center space-x-2">
              <input
                style={{ color: '#999' }}
                type="text"
                value={address}
                placeholder="Please enter token ID between [0, 9999]"
                className="input input-bordered input-primary w-full input-lg max-w-xl h-full m-0 text-white rounded-none"
                onChange={(e) => setAddress(e.target.value)}
              />
              <button
                className="btn btn-primary btn-md w-1/4 normal-case text-lg font-bold"
                onClick={executeScroll2}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div
          className="w-full inline-flex items-center justify-between"
          ref={start2Ref}
        >
          <div className="flex flex-col w-[30%]">
            <span className="uppercase text-lg text-primary">Step 2</span>
            <span className="uppercase text-2xl text-white font-bold">
              Get all transactions and make them human readable
            </span>
          </div>
          <div className="flex flex-col w-1/2 space-y-2">
            <span className="uppercase text-lg text-white text-opacity-30">
              Your Token ID
            </span>
            <span className="uppercase text-white font-bold text-lg">
              {address}
            </span>
          </div>
        </div>
        <div className="w-full inline-flex h-screen">
          <div className="flex flex-col w-[calc(50%_-_1px_-_2rem)] h-full bg-black bg-opacity-10">
            <div className="inline-flex p-6 items-center border-y-[1px] border-gray-600 space-x-3">
              <img src={Shock} alt="shock emoji" />
              <h3 className="text-white text-2xl font-bold whitespace-nowrap">
                Current <span className="text-red-800 font-bold">Messy</span>{' '}
                Integration
              </h3>
            </div>
            <div className="w-full h-full p-6 flex flex-col space-y-2">
              <div className="inline-flex w-full items-center">
                {INFO.map(({ id, logo }, index) => (
                  <div
                    key={id}
                    className="inline-flex w-1/5 items-center border-gray-600 border-r-0"
                  >
                    <div
                      className={classNames(
                        'flex items-center h-[3rem] w-full px-2 space-x-2 text-primary',
                        currentStep === index ? 'bg-gray-800' : 'bg-[#1B1A1D]'
                      )}
                    >
                      <img src={logo} alt="logo" className="h-[1rem]" />
                      <span className="text-white uppercase text-xs">{id}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="h-1" />
              <div className="h-[130px] flex flex-col">
                <div className="inline-flex items-center text-accent uppercase text-lg">
                  Step {currentStep + 1}{' '}
                  {/* <div className="border-[1px] border-accent w-10 mx-2" />
                {INFO[currentStep].id} */}
                </div>
                <span className="text-white uppercase text-xl font-semibold">
                  {INFO[currentStep].title}
                </span>
                <span className="text-accent">{INFO[currentStep].desc}</span>
              </div>
              <CodeSection
                address={address}
                key={INFO[currentStep].id}
                {...INFO[currentStep]}
                onExecuteText={`${INFO[currentStep].onExecuteText}`.replace(
                  'XOOy0o9G6gEyFr1ksrQNlg3QT8fFmv4x3GQxpvVkHII0L7PVNMB5HvYWmJs0l0uh',
                  '****'
                )}
                prevRuntime={totalRuntime
                  .slice(0, currentStep + 1)
                  .reduce((sum, curr) => sum + curr, 0)}
                prevLines={numberOfLines
                  .slice(0, currentStep + 1)
                  .reduce((sum, curr) => sum + curr, 0)}
                onComplete={(payload, runtime) => {
                  setNumberOfLines(
                    produce(numberOfLines, (draft) => {
                      draft[currentStep] =
                        (
                          `${INFO[currentStep].onExecuteText}`.match(/\n/g) ||
                          ''
                        ).length + 1;
                    })
                  );
                  setTotalRuntime(
                    produce(totalRuntime, (draft) => {
                      draft[currentStep] = runtime;
                    })
                  );
                  if (INFO[currentStep].id === 'Step4') {
                    // setTablePayload(payload);
                  } else {
                    setApiPayloads(
                      produce(apiPayloads, (draft) => {
                        draft[currentStep] = payload;
                      })
                    );
                  }
                }}
                rpcs={totalRpcs}
                beforeExecute={() => setTablePayload(null)}
                onExecute={async () => {
                  if (INFO[currentStep].id === 'Txns') {
                    if (!address) {
                      return;
                    }
                    const res = await INFO[currentStep].onExecute(
                      '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
                      address
                    );

                    setTransactions(res.result || []);

                    const newRpcs = [...rpcs];
                    newRpcs[0] = 1;
                    setRpcs(newRpcs);

                    return res;
                  }
                  if (INFO[currentStep].id === 'Price') {
                    const newTransactions = await INFO[1].onExecute(
                      transactions
                    );
                    // setPrice(res.data.priceUsd || 0);
                    setTransactions(newTransactions);

                    const newRpcs = [...rpcs];
                    newRpcs[1] = 1;
                    setRpcs(newRpcs);

                    return newTransactions;
                  }
                  if (INFO[currentStep].id === 'Platform') {
                    const newTransactions = await INFO[currentStep].onExecute(
                      transactions
                    );

                    const newRpcs = [...rpcs];
                    newRpcs[2] = newTransactions.length - 1;
                    setRpcs(newRpcs);

                    setTransactions(newTransactions);
                    return newTransactions;
                  }
                  if (INFO[currentStep].id === 'ENS') {
                    const allAddressMap = await INFO[currentStep].onExecute(
                      transactions
                    );

                    const newRpcs = [...rpcs];
                    newRpcs[3] = 4;
                    console.log({ newRpcs });
                    setRpcs(newRpcs);

                    const newTransactions = transactions.map((e: any) => ({
                      ...e,
                      fromDisplayName:
                        allAddressMap.get(e.from_address) ||
                        (e.from_address ===
                        '0x0000000000000000000000000000000000000000'
                          ? 'Null Address'
                          : e.from_address),
                      toDisplayName:
                        allAddressMap.get(e.to_address) || e.to_address,
                    }));

                    setTablePayload(newTransactions);
                    setUseZB(false);

                    return newTransactions;
                  }
                }}
              />
              <div className="inline-flex items-center w-full justify-between">
                <button
                  className="btn btn-outline btn-md w-1/4 normal-case font-bold text-lg"
                  disabled={currentStep === 0}
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                >
                  Back
                </button>
                <span className="text-white text-lg">
                  {' '}
                  {currentStep + 1} / 4{' '}
                </span>
                <button
                  className="btn btn-primary btn-md w-1/4 normal-case font-bold text-lg"
                  disabled={currentStep === 3}
                  onClick={() => setCurrentStep((prev) => prev + 1)}
                >
                  Next Step
                </button>
              </div>
            </div>
          </div>
          <div className="relative w-[2px] h-full bg-gray-600">
            <span className="absolute -right-9 top-1 bg-primary rounded-full p-5 text-2xl font-bold text-white">
              VS
            </span>
          </div>
          <div className="flex flex-col w-[calc(50%_-_1px_-_2rem)] h-full bg-black bg-opacity-10">
            <div className="inline-flex p-6 items-center justify-end border-y-[1px] border-gray-600 space-x-3">
              <h3 className="text-white text-2xl font-bold whitespace-nowrap">
                Our{' '}
                <span className="text-primary font-bold">Simple One-Step</span>{' '}
                Integration
              </h3>
              <img src={Chill} alt="chill emoji" />
            </div>
            <div className="w-full h-full p-6 flex flex-col space-y-2">
              <div className="inline-flex w-full items-center">
                <div className="flex items-center h-[3rem] w-full px-4 space-x-4 text-primary bg-gradient-to-r from-transparent to-[#3C404D] border-[1px] border-gray-600 border-r-0">
                  <img
                    src={ZettaWhite}
                    alt="zetta logo"
                    className="h-[1.5rem]"
                  />
                  <span className="text-white uppercase font-bold text-[1.5rem]">
                    ZettaBlock
                  </span>
                </div>
                <div className="border-solid border-l-[#3C404D] border-l-[1.5rem] border-y-transparent border-y-[1.5rem] border-r-0" />
              </div>
              <div className="h-1" />
              <div className="h-[130px] flex flex-col">
                <span className="text-yellow-400 uppercase text-lg flex justify-between">
                  Only One Step!
                  <a
                    className="text-stone-200 tooltip font-bold"
                    data-tip="hello"
                    href="https://app.zettablock.com/my-workspace/apis/sq_5934032b98f34b1c9305c56062b6483f/overview"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visit our API page
                  </a>
                </span>
                <span className="text-white uppercase text-xl font-semibold">
                  Call a ZettaBlock Endpoint
                </span>
                <span className="text-accent">
                  Send a request to an endpoint that runs a SQL command that
                  gets and transforms data through ZettaBlock's powerful data
                  infrastructure.
                </span>
              </div>
              <CodeSection
                address={address}
                {...ZTBL_INFO}
                rpcs={totalRpcsForZB}
                onExecute={async () => {
                  const records = await ZTBL_INFO.onExecute(address);

                  setTotalRpcsForZB(1);
                  const newTablePayloads = records.map((e: any) => {
                    return {
                      transaction_hash: e.transaction_hash,
                      block_timestamp: e.block_time,
                      type:
                        e.trade_type === 'Trade'
                          ? `${e.trade_type} at ${e.platform || ''}`
                          : e.trade_type,
                      displayPrice: e.amount ? `${e.amount} ETH (${e.usd_amount.toFixed(2)} USD)` : '',
                      fromDisplayName:
                        e.seller ||
                        (e.seller_address ===
                        '0x0000000000000000000000000000000000000000'
                          ? ''
                          : e.seller_address),
                      toDisplayName: e.buyer || e.buyer_address,
                    };
                  });

                  setTablePayload(newTablePayloads);
                  setUseZB(true);

                  return records;
                }}
                onExecuteText={`${ZTBL_INFO.onExecuteText}`}
                beforeExecute={() => setTablePayload(null)}
                prevRuntime={ztblRuntime}
                onComplete={(payload, runtime) => {
                  // setTablePayload(payload);
                  setZtblRuntime(runtime);
                }}
              />
              {/* <span className="uppercase text-white self-center">
                Complete!
              </span> */}
            </div>
          </div>
        </div>
      </div>
      {tablePayload && (
        <>
          <div className="h-[6vh]" />
          <div
            className={classNames(
              'flex flex-col justify-center max-h-screen w-screen px-[8vw] space-y-5',
              tablePayload ? 'transition-transform flex' : 'hidden'
            )}
          >
            <span className="font-bold text-white text-2xl">
              Generated Table - {useZB ? 'with' : 'without'} ZettaBlock
            </span>
            <div className="flex justify-center">
              <img
                style={{ width: 300 }}
                src={`https://storage.googleapis.com/nftimagebucket/tokens/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/preview/${address}.png`}
                alt={address}
              />
            </div>
            <div className="h-full w-full overflow-x-auto">
              <table className="table table-compact w-full">
                <thead>
                  <tr>
                    {[
                      'Txn Hash',
                      'Txn Time',
                      'Action',
                      'Price',
                      'From',
                      'To',
                    ].map((key) => (
                      <th key={key}>{capitalize(key)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tablePayload.map((transaction: any) => (
                    <tr>
                      {[
                        'transaction_hash',
                        'block_timestamp',
                        'type',
                        'displayPrice',
                        'fromDisplayName',
                        'toDisplayName',
                      ].map((col, index) => {
                        if (col === 'transaction_hash') {
                          return (
                            <td
                              id={`${index} ${col}`}
                              className="max-w-[100px] overflow-scroll text-ellipsis"
                            >
                              <a
                                href={`https://etherscan.io/tx/${transaction[col]}`}
                              >
                                {transaction[col]}
                              </a>
                            </td>
                          );
                        }
                        return (
                          <td
                            id={`${index} ${col}`}
                            className="max-w-[100px] overflow-scroll text-ellipsis"
                          >
                            {transaction[col]}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <div className="h-[15vh]" />
      <div className="flex flex-col w-full items-center justify-center px-[8vw] py-[2vh]">
        <span className="text-4xl text-white font-bold w-1/3 text-center">
          The First Full-stack, Real-time Web3 Data Infra
        </span>
        <div className="h-10" />
        <a
          rel="noreferrer"
          target="_blank"
          href="https://discord.com/invite/zettablock"
          className="btn btn-primary btn-lg normal-case inline-flex items-center gap-10 text-xl"
        >
          Apply for Early Access
          <AiOutlineArrowRight size="1.25rem" />
        </a>
      </div>
      <div className="h-[15vh]" />
      <div className="bg-black w-full h-full px-[8vw] py-[5vh] inline-flex justify-between">
        <div className="w-[40%] flex flex-col items-start">
          <img src={Logo} alt="logo" className="h-[3vh] w-auto" />
          <div className="h-3" />
          <span className="text-accent w-1/3">
            The First Full-stack, Real-time Web3 Data Infra
          </span>
          <div className="h-6" />
          <span className="text-white uppercase text-lg font-bold">
            Sign Up & Get News
          </span>
          <div className="h-6" />
          <div className="w-3/4 relative inline-flex items-center">
            <input
              className="input input-bordered w-full bg-white"
              placeholder="Please Input Email"
            />
            <button className="btn btn-sm btn-circle btn-ghost absolute right-3 text-primary">
              <FiSend size="1.25rem" />
            </button>
          </div>
        </div>
        <div className="w-[40%] flex flex-col items-start justify-between">
          <div className="flex flex-col w-full space-y-5">
            <span className="w-full text-lg">
              We are hiring. Come to work with us at ZettaBlock!
            </span>
            <span className="w-full text-lg">
              Careers: founders@zettablock.com
            </span>
          </div>
          <div className="flex flex-col w-full space-y-5">
            <span className="text-white uppercase text-lg font-bold">
              Follow Us
            </span>
            <div className="inline-flex space-x-5 items-center w-full">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/zettablockhq/"
                className="bg-white p-2 rounded-md w-fit"
              >
                <FaLinkedinIn size="1.25rem" className="text-primary" />
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/ZettaBlockHQ"
                className="bg-white p-2 rounded-md w-fit"
              >
                <BsTwitter size="1.25rem" className="text-primary" />
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href="https://zettablock.readme.io/docs"
                className="bg-white p-2 rounded-md w-fit"
              >
                <FaSlackHash size="1.25rem" className="text-primary" />
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href="https://discord.com/invite/zettablock"
                className="bg-white p-2 rounded-md w-fit"
              >
                <SiDiscord size="1.25rem" className="text-primary" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary w-full h-full px-[8vw] py-[3vh] inline-flex justify-between">
        <div className="inline-flex space-x-5">
          <a href="/">Privacy Policy</a>
          <a href="/">Term of service</a>
          <a href="/">Language</a>
        </div>
        <div className="text-white">
          © 2022&nbsp;&nbsp;&nbsp;ZettaBlock Inc.
        </div>
      </div>
    </div>
  );
}
