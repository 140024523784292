import React, { memo, useState } from "react";
import { InputDate, getTimeInSec, formatTime } from "./utils";

function StopWatch({
  startedAt,
  endedAt,
  className,
}: {
  startedAt?: InputDate;
  endedAt?: InputDate;
  className?: string;
}) {
  const [time, setTime] = useState<number>(0);
  const running = !!startedAt && !endedAt;

  React.useEffect(() => {
    let interval: NodeJS.Timer | null = null;

    if (running === true) {
      interval = setInterval(() => {
        const now = getTimeInSec(new Date());
        const newTime = now - getTimeInSec(startedAt);
        setTime(newTime);
      }, 30);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [running]);

  const getRenderTime = (start: InputDate, end: InputDate) => {
    // when endedAt not provided
    if (running) {
      return time;
    }

    // when endedAt provided we calculate with it
    const runtime = getTimeInSec(end) - getTimeInSec(start);

    return runtime;
  };

  return (
    <span className={className}>
      {formatTime(getRenderTime(startedAt, endedAt))}
    </span>
  );
}

export default memo(StopWatch);
