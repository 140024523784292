import Alchemy from './assets/alchemy.png';
import Moralise from './assets/moralis.png';
import ENSLogo from './assets/ENS.png';
import CoinGecko from './assets/coingecko.png';
import LogoIcon from './assets/zetta-icon.png';
import axios from 'axios';
// import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
// import coins_mapping from './coins_mapping';
// import { format } from 'date-fns';
import { ENS } from '@ensdomains/ensjs';
import { ethers } from 'ethers';
// import openApi from 'api';
interface Transaction {
  value: string;
  from_address: string;
  to_address: string;
  transaction_hash: string;
  block_timestamp: string;
}

const INFO: {
  id: string;
  title: string;
  desc: string;
  logo: string;
  onExecute: Function;
  onExecuteText: string;
}[] = [
  {
    id: 'Txns',
    title: 'Get all transactions from a Node Provider',
    desc: 'Use node providers like Alchemy or Moralis to get all transactions for a particular token. ',
    logo: Moralise,
    onExecuteText: `
    async (address: string, tokenId: string) => {
      if (!address || !tokenId) return;

      const options = {
        method: 'GET',
        url: 'https://deep-index.moralis.io/api/v2/nft/$\{address}/$\{tokenId}/transfers',
        params: { chain: 'eth', format: 'decimal' },
        headers: {
          accept: 'application/json',
          'X-API-Key': process.env.REACT_APP_MORALIS!,
        },
      };

      try {
        const res = await axios.request(options);
        return res.data;
      } catch (error) {
        throw new Error('Something is wrong!');
      }
    }
    `,
    onExecute: async (address: string, tokenId: string) => {
      if (!address || !tokenId) return;

      const options = {
        method: 'GET',
        url: `https://deep-index.moralis.io/api/v2/nft/${address}/${tokenId}/transfers`,
        params: { chain: 'eth', format: 'decimal' },
        headers: {
          accept: 'application/json',
          'X-API-Key': process.env.REACT_APP_MORALIS!,
        },
      };

      try {
        const res = await axios.request(options);
        return res.data;
      } catch (error) {
        throw new Error('Something is wrong!');
      }
    },
  },
  {
    id: 'Price',
    title: 'Get the price data',
    desc: 'Use APIs like CoinGecko or CoinCap to get more information about the ERC20 token prices.',
    logo: CoinGecko,
    onExecuteText: `
    async (transactions: Transaction[]) => {
      try {
        const res = await axios.get(
          'https://api.coincap.io/v2/assets/ethereum'
        );

        const currentPrice = parseInt(res.data.data.priceUsd, 10);

        return transactions.map((transaction) => {
          const amount = parseInt(transaction.value, 10) / 10 ** 18;
          return {
            ...transaction,
            displayPrice: amount
              ? '$\{amount} ETH ($$\{amount * currentPrice})'
              : '',
          };
        });
      } catch (error) {
        throw new Error('Something is wrong!');
      }
    }`,
    onExecute: async (transactions: Transaction[]) => {
      try {
        const res = await axios.get(
          'https://api.coincap.io/v2/assets/ethereum'
        );

        const currentPrice = parseInt(res.data.data.priceUsd, 10);

        return transactions.map((transaction) => {
          const amount = parseInt(transaction.value, 10) / 10 ** 18;
          return {
            ...transaction,
            displayPrice: amount
              ? `${amount} ETH ($${amount * currentPrice})`
              : '',
          };
        });
      } catch (error) {
        throw new Error('Something is wrong!');
      }
    },
  },
  {
    id: 'Platform',
    title: 'get transaction type and platform info',
    desc: 'Use APIs like alchemy to get more information about transaction type and platform, in particular on what platform this token is traded. ',
    logo: Alchemy,
    onExecuteText: `
    async (transactions: any) => {
      const getTransactionType = (logs: string[]) => {
        if (!logs || !logs.length) return '';

        if (
          logs.some(
            (log: any) =>
              log.address === '0x74312363e45dcaba76c59ec49a7aa8a65a67eed3'
          )
        ) {
          return 'Sale at X2Y2';
        } // X2Y2

        if (
          logs.some(
            (log: any) =>
              log.address === '0x59728544b08ab483533076417fbbb2fd0b17ce3a'
          )
        ) {
          return 'Sale at LooksRare';
        } // LooksRare

        if (
          logs.some(
            (log: any) =>
              log.address === '0x00000000006CEE72100D161c57ADA5Bb2be1CA79' ||
              log.address === '0x00000000006c3852cbef3e08e8df289169ede581'
          )
        ) {
          return 'Sale at opensea';
        } // opensea

        return 'Transfer';
      };

      const result = await Promise.all(
        transactions.map(async (transaction: Transaction) => {
          if (
            transaction.from_address ===
            '0x0000000000000000000000000000000000000000'
          ) {
            return {
              ...transaction,
              type: 'Mint',
            };
          }

          const transaction_hash = transaction.transaction_hash;

          const res = await axios.post(
            'https://eth-mainnet.g.alchemy.com/v2/xxxxxxxx=xx=',
            {
              id: 1,
              jsonrpc: '2.0',
              method: 'eth_getTransactionReceipt',
              params: [transaction_hash],
            }
          );

          const logs = res.data.result.logs; // get the logs

          return {
            ...transaction,
            type: getTransactionType(logs),
          };
        })
      );

      return result;
    }`,
    onExecute: async (transactions: any) => {
      const getTransactionType = (logs: string[]) => {
        if (!logs || !logs.length) return '';

        if (
          logs.some(
            (log: any) =>
              log.address === '0x74312363e45dcaba76c59ec49a7aa8a65a67eed3'
          )
        ) {
          return 'Trade at X2Y2';
        } // X2Y2

        if (
          logs.some(
            (log: any) =>
              log.address === '0x59728544b08ab483533076417fbbb2fd0b17ce3a'
          )
        ) {
          return 'Trade at LooksRare';
        } // LooksRare

        if (
          logs.some(
            (log: any) =>
              log.address === '0x00000000006CEE72100D161c57ADA5Bb2be1CA79' ||
              log.address === '0x00000000006c3852cbef3e08e8df289169ede581' ||
              log.address === '0x7be8076f4ea4a4ad08075c2508e481d6c946d12b'
          )
        ) {
          return 'Trade at opensea';
        } // opensea

        return 'Transfer';
      };

      const result = await Promise.all(
        transactions.map(async (transaction: Transaction) => {
          if (
            transaction.from_address ===
            '0x0000000000000000000000000000000000000000'
          ) {
            return {
              ...transaction,
              type: 'Mint',
            };
          }

          const transaction_hash = transaction.transaction_hash;

          const res = await axios.post(
            'https://eth-mainnet.g.alchemy.com/v2/cYqjnuTjVcM2l80h0-Nr1l5id5lUHVGP',
            {
              id: 1,
              jsonrpc: '2.0',
              method: 'eth_getTransactionReceipt',
              params: [transaction_hash],
            }
          );

          const logs = res.data.result.logs; // get the logs

          return {
            ...transaction,
            type: getTransactionType(logs),
          };
        })
      );

      return result;
    },
  },
  {
    id: 'ENS',
    title: 'Call APIs to get ENS domain names',
    desc: 'Get ENS domain names and labels for all wallet addresses in the transaction histories.',
    logo: ENSLogo,
    onExecute: async (transactions: any) => {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://eth-mainnet.alchemyapi.io/v2/cOfjMxz__PXigvETbXKyxroh9Nsloplr'
      );

      const ENSInstance = new ENS();
      await ENSInstance.setProvider(provider);

      const addressesSet = new Set();
      transactions.forEach((transaction: Transaction) => {
        addressesSet.add(transaction.from_address);
        addressesSet.add(transaction.to_address);
      });

      const allAddressesNames = (Array.from(addressesSet) as string[]).map(
        (address: string) => {
          return ENSInstance.getName.batch(address);
        }
      );

      const batched = (await ENSInstance.batch(...allAddressesNames)) || [];
      const allAddressMap = new Map();
      Array.from(addressesSet).forEach((e, index) => {
        allAddressMap.set(e, batched[index]?.name || undefined);
      });

      return Promise.resolve(allAddressMap);
    },
    onExecuteText: `
    async (transactions: any) => {
      const provider = new ethers.providers.JsonRpcProvider(
        'https://eth-mainnet.alchemyapi.io/v2/cOfjMxz__PXigvETbXKyxroh9Nsloplr'
      );

      const ENSInstance = new ENS();
      await ENSInstance.setProvider(provider);

      const addressesSet = new Set();
      transactions.forEach((transaction: Transaction) => {
        addressesSet.add(transaction.from_address);
        addressesSet.add(transaction.to_address);
      });

      const allAddressesNames = (Array.from(addressesSet) as string[]).map(
        (address: string) => {
          return ENSInstance.getName.batch(address);
        }
      );

      const batched = (await ENSInstance.batch(...allAddressesNames)) || [];
      const allAddressMap = new Map();
      Array.from(addressesSet).forEach((e, index) => {
        allAddressMap.set(e, batched[index]?.name || undefined);
      });

      return Promise.resolve(allAddressMap);
    }`,
  },
];

export const ZTBL_INFO = {
  id: 'ZettaBlock',
  title: 'Call a ZettaBlock endpoint',
  desc: "Send a request to an endpoint that runs a SQL command that gets and transforms data through ZettaBlock's powerful data infrastructure.",
  logo: LogoIcon,
  sql: `SELECT 'erc20' type, contract_address, transaction_hash, evt_index, NULL operator, from_address, to_address, NULL token_id, NULL token_id_hex, value, symbol, NULL name, decimals, price, block_number, block_time, data_creation_date
        FROM
          (
            SELECT *
            FROM ethereum_mainnet.erc20_evt_transfer
            WHERE from_address = '%s'
          ) t
          LEFT JOIN (
            SELECT symbol,
              DATE(data_creation_date) data_creation_date,
              AVG(price) price
            FROM prices.usd
            GROUP BY 1, 2
          ) p USING (symbol, data_creation_date)
        UNION
        SELECT 'erc721' type, contract_address, transaction_hash, evt_index, NULL operator, from_address, to_address, token_id, token_id_hex, '1' value, symbol, name, 0 decimals, NULL price, block_number, block_time, data_creation_date
        FROM ethereum_mainnet.erc721_evt_transfer
        WHERE from_address = '%s'
        UNION
        SELECT 'erc1155' type, contract_address, transaction_hash, evt_index, operator, from_address, to_address, id token_id, id_hex token_id_hex, value, symbol, name, 0 decimals, NULL price, block_number, block_time, data_creation_date
        FROM ethereum_mainnet.erc1155_evt_transfer_single
        WHERE from_address = '%s' `,
  onExecuteText: `
  async (tokenId: any) => {
    const res = await axios.post(
      'https://api.zettablock.com/api/v1/dataset/sq_50a1df8ecdca4245859b46bf7851b2c5/graphql',
      {
        query:
          '{ records(token_id: "' +
          tokenId +
          '", orderBy: block_time, orderDirection: desc) {   amount  platforms  currency   symbol 	token_id   block_time   usd_amount   buyer_address   buyer   seller_address   seller   trade_type   transaction_hash }}',
      },
      {
        headers: {
          'X-API-KEY':
            process.env.REACT_APP_ZB
          'content-type': 'application/json',
          accept: 'application/json',
        },
      }
    );
    const records = res?.data?.data?.records || [];

    return records;
  },
};
  `,
  onExecute: async (tokenId: any) => {
    const res = await axios.post(
      'https://api.zettablock.com/api/v1/dataset/sq_5934032b98f34b1c9305c56062b6483f/graphql',
      {
        query:
          '{ records(token_id: "' +
          tokenId +
          '", orderBy: block_time, orderDirection: desc) {   amount platform  currency   symbol 	token_id   block_time   usd_amount   buyer_address   buyer   seller_address   seller   trade_type   transaction_hash }}',
      },
      {
        headers: {
          'X-API-KEY': process.env.REACT_APP_ZB!,
          'content-type': 'application/json',
          accept: 'application/json',
        },
      }
    );
    const records = res?.data?.data?.records || [];

    return records;
  },
};

export default INFO;
